.HomeDiv { 
    height: auto;
    width:100%;
    position: relative;
    background-image: url(./Images/heroimage.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 32.52%;
    display: flex;
}

.IntroHeaderDiv {
    font-size: 3vw;
    text-align: right;
    top:0;
    right:6.75%;
    z-index: 10;
    position: absolute;
    word-spacing: 10pt;
    color:#EBA362;
    height: auto;
}

.IntroTextDiv {
    right:17.5%;
    top:35%;
    position: absolute;
    text-align: left;
    width: 30%;
    height: 50%;
    font-weight: 300;
    display: flex;
    flex-direction: column;
}



.IntroTextDiv p{
    color:#000;
    margin-top:10px;
    margin-bottom:10px;
}


.WorkDiv {
    height: auto;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 30px;

}

.WorkGrid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px 20px;
    justify-content: center;
    position: relative;
    margin: 0;

}

.HamburgerMenu {
    display: none !important;
    transform: scale(1.5);
    cursor: pointer;
}

.HamburgerMenu:hover {
    fill: #EBA362;
}

.MenuDropdown {
    display: none;
}

.MenuDropdown-clicked {
    display: none;
}

@media (max-width: 1200px) {
    .WorkGrid {
        grid-template-columns: auto auto;
    }
    .IntroTextDiv {
        right:3%;
        width:45%;
        top:33%;
        height:65%;
    }
    .headshotSpacer {
        padding-right: 3.5%;
    }
    .headshot {
        margin-left: 12% !important;
        padding-top: 35% !important;
    }
    .aboutparagraph {
        width: 75% !important;
    }
    .MenuDropdown-clicked {
        display: none;
    }
}

@media (max-width: 850px) {
    .headshot {
        margin-left: 10% !important;
    }
}


@media (max-width: 750px) {
    .WorkGrid {
        grid-template-columns: auto;
    }
    .HomeDiv {
        background-image: none;
        flex-direction: column;
        padding-top: 10px;
        justify-content: center;
    }
    .IntroHeaderDiv {
        text-align: center;
        position: static;
        font-size: 4.5vw;
        word-spacing: 5pt;
    }
    .IntroTextDiv {
        text-align: center;
        position: static;
        width: auto;
        padding-right: 15%;
        padding-left: 15%;
    }
    .IntroHeaderDiv h1 {
        margin-bottom: 3%;
    }
    .headshotWrapper {
        position:static !important;
    }
    .headshotSpacer {
        opacity: 1 !important;
        padding-top:50% !important;
        background-image: url(./Images/headshotedited.png);
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto !important;
        margin-top: 10% !important;
        background-position: center;
        padding-right: 0px !important;
        width: 100% !important;
    }
    .aboutparagraph h2 {
        text-align: center;
        text-indent: 0pt;
    }
    .email {
        text-align: center;
        text-indent: 0pt;
    }
    .headshot {
        display: none;
    }
    .AboutText {
        padding-top:0 !important;
    }
    .AboutTitle {
        margin-left: 0 !important;
        margin-top: 5% !important;
        margin-bottom: 2% !important;
    }
    .AboutTitle h1 {
        text-align: center;
        font-size: 10vw;
    }
    .AboutWrapper {
        justify-content: center !important;
        flex-direction: column;
    }
    .aboutparagraph {
        padding-left: 0 !important;
        width: auto !important;
        padding-right: 9% !important;
        padding-left: 9% !important;
        text-indent: 15pt;
    }
    .H1_Link {
        display: none;    
    }
    .HamburgerMenu {
        display: block !important;
        z-index: 100;
        margin-right: 5vw;
    }
    .MenuDropdown-clicked {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: lightgray !important;
        border-right: 0px;
        border-top: 0px;
        z-index: 50;
        right: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0.95 !important;
    }
    .H1_Header {
        font-size: 25px !important;
    }
    .H1_Header_Current {
        font-size: 25px !important;
    }
    .H1_Logo {
        margin-left: 5vw !important;
    }
    
}

.GridItem1 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(./Images/UXgrid.png);
    background-size: 100%;
    position: relative;
}

.GridItem2 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(./Images/accruegrid.png);
    background-size: 100%;
    position: relative;
}
.GridItem3 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(./Images/pohgrid.png);
    background-size: 100%;
    position: relative;
}
.GridItem4 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(./Images/tigerliliesgrid.png);
    background-size: 100%;
    position: relative;
}
.GridItem5 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(./Images/bookletgrid.png);
    background-size: 100%;
    position: relative;
}
.GridItem6 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(./Images/beergrid2.png);
    background-size: 100%;
    position: relative;
}

.GridItemText {
    padding: 30px;
    font-weight: 300;
}

.GridOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10pt;
    background: rgba(0,0,0,0.8);
    color: white;
    font-size: 30px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.5s;

}

.GridOverlay > * {
    transform: translateY(20px);
    transition: transform 0.5s;
}

.GridOverlay:hover {
    opacity: 1;

}

.GridOverlay:hover > * {
    transform: translateY(0);
}

.AboutWrapper {
    display: flex;

}
.headshotSpacer{
    height:100%;
    width:110%;
    opacity: 0;
    margin-left:20%;
    margin-top: 2%;
    padding-top:30%;
}

.headshot{
    position: sticky;
    top:20%;
    margin-left:18%;
    z-index:1;
    opacity: 1;
    padding-top:30%;
    background-image: url(./Images/headshotedited.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.headshotWrapper{
    position: absolute;
    height:100%;
    width: 100%;
}

.AboutText{
    display:flex;
    flex-direction: column;
    padding-top: 50px;
}
.AboutTitle {
    font-size: 2em;
    word-spacing: 10pt;
    text-align: left;
    margin-top:80px;
    margin-bottom:0px;
    margin-left:-6%;
    z-index: 2;
}
.AboutTitle h1{
    margin:0;
}

.aboutparagraph{
    display:flex;
    flex-direction:column;
    text-align:left;
    width:65%;
    font-weight: 300;
    line-height: 26px;
    padding-left: 60px;
}

.FooterBar {
    width: 100%;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    z-index: 100;
    padding-bottom: 30px;

}

.FooterSpacer {
    width: 75%;
    height: 1px;
    background-color: darkgray;
    margin-bottom: 30px;
    margin-top: 30px;
}

.Icons{
    color: darkgray;
    padding-left: 30px;
    padding-right: 30px;
    transform: scale(1.2);
    
}

.Icons:hover{
    color: black;
}

.HeaderBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    background-color: white;
    height: 10vh;
    width: auto;
    top: 0;
    z-index: 100000;
    padding-right: 3vw;
}

.NavButtons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: auto;
    right : 0;
}

.H1_Logo { 
    margin-left: 3vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 100;
}

.HBLogo {
    height: 25pt;
    width: 25pt;
    z-index: 100;
}

.LogoSpacer {
    height: 6vh;
    width: 1px;
    background-color: darkgray;
    margin-left: 3vw;
    z-index: 100;
}

.H1_Header_Name {
    margin-left: 3vw;
    margin-right: 3vw;
    font-size: 14pt;
    font-weight: 400;  
}

.H1_Header {
    margin-left: 3vw;
    margin-right: 3vw;
    font-size: 14pt;
    font-weight: 400;
}

.H1_Header_Current {
    margin-left: 3vw;
    margin-right: 3vw;
    font-size: 14pt;
    font-weight: 400;
    border-bottom: 2px solid #EBA362;
    padding-bottom: 2px;
}

.H1_Header_Hidden {
    display:none;
}

a:link {
    color: black;
    text-decoration: none;
}

a:visited {
    color: black;
    text-decoration: none;
}

a:hover {
    color:#EBA362;
    text-decoration: none;
}