@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family:'Source Sans Pro';
}

.Content{
  min-height: 70vh;
}
.HomeDiv { 
    height: auto;
    width:100%;
    position: relative;
    background-image: url(/static/media/heroimage.ff53420f.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 32.52%;
    display: -webkit-flex;
    display: flex;
}

.IntroHeaderDiv {
    font-size: 3vw;
    text-align: right;
    top:0;
    right:6.75%;
    z-index: 10;
    position: absolute;
    word-spacing: 10pt;
    color:#EBA362;
    height: auto;
}

.IntroTextDiv {
    right:17.5%;
    top:35%;
    position: absolute;
    text-align: left;
    width: 30%;
    height: 50%;
    font-weight: 300;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}



.IntroTextDiv p{
    color:#000;
    margin-top:10px;
    margin-bottom:10px;
}


.WorkDiv {
    height: auto;
    width: 100%;
    margin-top: 60px;
    margin-bottom: 30px;

}

.WorkGrid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 20px 20px;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    margin: 0;

}

.HamburgerMenu {
    display: none !important;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    cursor: pointer;
}

.HamburgerMenu:hover {
    fill: #EBA362;
}

.MenuDropdown {
    display: none;
}

.MenuDropdown-clicked {
    display: none;
}

@media (max-width: 1200px) {
    .WorkGrid {
        grid-template-columns: auto auto;
    }
    .IntroTextDiv {
        right:3%;
        width:45%;
        top:33%;
        height:65%;
    }
    .headshotSpacer {
        padding-right: 3.5%;
    }
    .headshot {
        margin-left: 12% !important;
        padding-top: 35% !important;
    }
    .aboutparagraph {
        width: 75% !important;
    }
    .MenuDropdown-clicked {
        display: none;
    }
}

@media (max-width: 850px) {
    .headshot {
        margin-left: 10% !important;
    }
}


@media (max-width: 750px) {
    .WorkGrid {
        grid-template-columns: auto;
    }
    .HomeDiv {
        background-image: none;
        -webkit-flex-direction: column;
                flex-direction: column;
        padding-top: 10px;
        -webkit-justify-content: center;
                justify-content: center;
    }
    .IntroHeaderDiv {
        text-align: center;
        position: static;
        font-size: 4.5vw;
        word-spacing: 5pt;
    }
    .IntroTextDiv {
        text-align: center;
        position: static;
        width: auto;
        padding-right: 15%;
        padding-left: 15%;
    }
    .IntroHeaderDiv h1 {
        margin-bottom: 3%;
    }
    .headshotWrapper {
        position:static !important;
    }
    .headshotSpacer {
        opacity: 1 !important;
        padding-top:50% !important;
        background-image: url(/static/media/headshotedited.620dd6ce.png);
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto !important;
        margin-top: 10% !important;
        background-position: center;
        padding-right: 0px !important;
        width: 100% !important;
    }
    .aboutparagraph h2 {
        text-align: center;
        text-indent: 0pt;
    }
    .email {
        text-align: center;
        text-indent: 0pt;
    }
    .headshot {
        display: none;
    }
    .AboutText {
        padding-top:0 !important;
    }
    .AboutTitle {
        margin-left: 0 !important;
        margin-top: 5% !important;
        margin-bottom: 2% !important;
    }
    .AboutTitle h1 {
        text-align: center;
        font-size: 10vw;
    }
    .AboutWrapper {
        -webkit-justify-content: center !important;
                justify-content: center !important;
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .aboutparagraph {
        padding-left: 0 !important;
        width: auto !important;
        padding-right: 9% !important;
        padding-left: 9% !important;
        text-indent: 15pt;
    }
    .H1_Link {
        display: none;    
    }
    .HamburgerMenu {
        display: block !important;
        z-index: 100;
        margin-right: 5vw;
    }
    .MenuDropdown-clicked {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        position: fixed;
        background-color: lightgray !important;
        border-right: 0px;
        border-top: 0px;
        z-index: 50;
        right: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        opacity: 0.95 !important;
    }
    .H1_Header {
        font-size: 25px !important;
    }
    .H1_Header_Current {
        font-size: 25px !important;
    }
    .H1_Logo {
        margin-left: 5vw !important;
    }
    
}

.GridItem1 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(/static/media/UXgrid.8d3fa741.png);
    background-size: 100%;
    position: relative;
}

.GridItem2 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(/static/media/accruegrid.ba274d5f.png);
    background-size: 100%;
    position: relative;
}
.GridItem3 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(/static/media/pohgrid.1e88de65.png);
    background-size: 100%;
    position: relative;
}
.GridItem4 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(/static/media/tigerliliesgrid.470b0dfc.png);
    background-size: 100%;
    position: relative;
}
.GridItem5 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(/static/media/bookletgrid.a90d530e.png);
    background-size: 100%;
    position: relative;
}
.GridItem6 {
    padding: 20px;
    height: 300px;
    width: 300px;
    border-radius: 8pt;
    background-image: url(/static/media/beergrid2.162786dd.png);
    background-size: 100%;
    position: relative;
}

.GridItemText {
    padding: 30px;
    font-weight: 300;
}

.GridOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 10pt;
    background: rgba(0,0,0,0.8);
    color: white;
    font-size: 30px;
    text-align: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 0;
    transition: opacity 0.5s;

}

.GridOverlay > * {
    -webkit-transform: translateY(20px);
            transform: translateY(20px);
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.GridOverlay:hover {
    opacity: 1;

}

.GridOverlay:hover > * {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.AboutWrapper {
    display: -webkit-flex;
    display: flex;

}
.headshotSpacer{
    height:100%;
    width:110%;
    opacity: 0;
    margin-left:20%;
    margin-top: 2%;
    padding-top:30%;
}

.headshot{
    position: -webkit-sticky;
    position: sticky;
    top:20%;
    margin-left:18%;
    z-index:1;
    opacity: 1;
    padding-top:30%;
    background-image: url(/static/media/headshotedited.620dd6ce.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.headshotWrapper{
    position: absolute;
    height:100%;
    width: 100%;
}

.AboutText{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-top: 50px;
}
.AboutTitle {
    font-size: 2em;
    word-spacing: 10pt;
    text-align: left;
    margin-top:80px;
    margin-bottom:0px;
    margin-left:-6%;
    z-index: 2;
}
.AboutTitle h1{
    margin:0;
}

.aboutparagraph{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    text-align:left;
    width:65%;
    font-weight: 300;
    line-height: 26px;
    padding-left: 60px;
}

.FooterBar {
    width: 100%;
    height: auto;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: white;
    z-index: 100;
    padding-bottom: 30px;

}

.FooterSpacer {
    width: 75%;
    height: 1px;
    background-color: darkgray;
    margin-bottom: 30px;
    margin-top: 30px;
}

.Icons{
    color: darkgray;
    padding-left: 30px;
    padding-right: 30px;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    
}

.Icons:hover{
    color: black;
}

.HeaderBar{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    position: -webkit-sticky;
    position: sticky;
    background-color: white;
    height: 10vh;
    width: auto;
    top: 0;
    z-index: 100000;
    padding-right: 3vw;
}

.NavButtons{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    width: auto;
    right : 0;
}

.H1_Logo { 
    margin-left: 3vw;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
    z-index: 100;
}

.HBLogo {
    height: 25pt;
    width: 25pt;
    z-index: 100;
}

.LogoSpacer {
    height: 6vh;
    width: 1px;
    background-color: darkgray;
    margin-left: 3vw;
    z-index: 100;
}

.H1_Header_Name {
    margin-left: 3vw;
    margin-right: 3vw;
    font-size: 14pt;
    font-weight: 400;  
}

.H1_Header {
    margin-left: 3vw;
    margin-right: 3vw;
    font-size: 14pt;
    font-weight: 400;
}

.H1_Header_Current {
    margin-left: 3vw;
    margin-right: 3vw;
    font-size: 14pt;
    font-weight: 400;
    border-bottom: 2px solid #EBA362;
    padding-bottom: 2px;
}

.H1_Header_Hidden {
    display:none;
}

a:link {
    color: black;
    text-decoration: none;
}

a:visited {
    color: black;
    text-decoration: none;
}

a:hover {
    color:#EBA362;
    text-decoration: none;
}
.colorheader-g {
    display:-webkit-flex;
    display:flex;
    background-color: #6CAD7A;
    width:100%;
    height:500px;
}

.colorheader-p {
    display:-webkit-flex;
    display:flex;
    background-color: #A3B0E2;
    width:100%;
    height:500px;
}
.headertext{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-align-items:center;
            align-items:center;
    color:white;
    width:100%;
    height:100%;
    -webkit-justify-content: center;
            justify-content: center;
}

.headertext h1{
    font-size: 65pt;
    margin: 0;
    text-align: center;
}

.headertext h3{
    font-size: 16pt;
    text-align: center;
    font-weight:300;
    width:50%;
}

.casestudy{
    width: 50%;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction:column;
            flex-direction:column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin:auto;
    padding-top: 1%;
    margin-bottom: 30px;
}

.casestudy h2{
    margin-top: 30px;
    margin-bottom: 0px;
}

.casestudy p{
    line-height: 26px;
    font-weight: 300;
}

.brandguide {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    height: auto;
    width:100%;
    background-image: url(/static/media/brandguide.6a24efcf.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 33.48%;
    margin-right: 2.5%;
}

.brandguideZoom {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 20;
    -webkit-transform: translate(50%,25%) scale(1.75);
            transform: translate(50%,25%) scale(1.75);
    height: auto;
    width:100%;
    background-image: url(/static/media/brandguide.6a24efcf.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.brandguideSmall {
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(/static/media/brandguide.6a24efcf.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
    margin-top: 3%;
    display: none;
}

.brandguide-hidden {
    height: auto;
    width:100%;
    background-image: url(/static/media/brandguide.6a24efcf.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 33.48%;
    margin-right: 2.5%;
    opacity: 0;
}

.brandbrochure {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    height: auto;
    width:100%;
    background-image: url(/static/media/brandbrochure.7d0513f8.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 33.48%;
    margin-left: 2.5%;
}

.brandbrochure-hidden {
    height: auto;
    width:100%;
    background-image: url(/static/media/brandbrochure.7d0513f8.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 33.48%;
    margin-left: 2.5%;
    opacity:0;
}

.brandbrochureZoom {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 20;
    -webkit-transform: translate(-50%,25%) scale(1.75);
            transform: translate(-50%,25%) scale(1.75);
    height: auto;
    width:100%;
    background-image: url(/static/media/brandbrochure.7d0513f8.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.brandbrochureSmall {
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(/static/media/brandbrochure.7d0513f8.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
    margin-top: 10%;
    display: none;
}

.UXimage {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(/static/media/bankapp.06474471.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 106.87%;
}

.UXimageZoom {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 15;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(/static/media/bankapp.06474471.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 106.87%;
}

.AccrueImage {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(/static/media/accrue.ff0aad15.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.AccrueImageZoom {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 15;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(/static/media/accrue.ff0aad15.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.FrisbeeImage {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(/static/media/frisbeesite.ed97614a.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.FrisbeeImageZoom {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 15;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(/static/media/frisbeesite.ed97614a.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.OctoholicImage {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(/static/media/octoholicproject.f33dad07.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 44.83%;
}

.OctoholicImageZoom {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 15;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(/static/media/octoholicproject.f33dad07.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 44.83%;
}

.POHImage1 {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    height: auto;
    width:100%;
    background-image: url(/static/media/poh2.12b09c6c.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 62.78%;
}

.POHImage1Zoom {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 15;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(/static/media/poh2.12b09c6c.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 62.78%;
}

.POHImage2 {
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
    height: auto;
    width:100%;
    background-image: url(/static/media/poh1.39ff5d21.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 62.78%;
    margin-top: 2.5%;
}

.POHImage2Zoom {
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
    z-index: 15;
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(/static/media/poh1.39ff5d21.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 62.78%;
}

.container {
    position: relative;
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.blurOverlay {
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    background-color: #7777;
    z-index: -1;
    position: absolute;
}

.blurOverlay-blur {
    top: 0;
    bottom: 0;
    width: 100%;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    opacity: 1;
    z-index: 10;
    position: absolute;
}

.blurOverlay-brand {
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    background-color: #7777;
    z-index: -1;
    position: absolute;
}

.blurOverlay-brand-blur {
    top: 0;
    bottom: 0;
    width: 100%;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    opacity: 1;
    z-index: 10;
    position: absolute;
}

.boldLink {
    font-weight: 600;
}

.iframe{
    border: none;
    width: 100%;
    height: 400px;
}

.imagegrid{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    width:75%;
}

.topgrid{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;

}

.bottomgrid{
    display:-webkit-flex;
    display:flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

@media (max-width:1200px) {
    .casestudy {
        width: 60%;
    }
    .navigationArrows {
        width: 60% !important;
    }
    .UXimage {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .AccrueImage {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .FrisbeeImage {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .OctoholicImage {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .POHImage1 {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .POHImage2 {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .brandguide {
        max-height: 100%;
        height: auto;
        object-fit: contain;
        width: 50%;
        display: none;
    }
    .brandbrochure {
        max-height: 100%;
        height: auto;
        object-fit: contain;
        width: 50%;
        display: none;
    }
    .brandguideSmall {
        display: block;
    }
    .brandbrochureSmall {
        display: block;
    }
    .blurOverlay-brand-blur {
        display: none;
    }
    .brandguideZoom {
        display: none;
    }
    .brandbrochureZoom {
        display: none;
    }
    .topgrid {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }
    .bottomgrid {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .iframe {
        margin-top: 5%;
    }
}

@media (max-width: 750px) {
    .headertext h1 {
        font-size: 35pt;
    }
    .casestudy {
        width: 75%;
    }
    .navigationArrows {
        width: 75% !important;
    }
    .UXimage {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: auto;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .UXimageZoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        -webkit-transform: scale(1);
                transform: scale(1);
        cursor: auto;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .AccrueImage {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: auto;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .AccrueImageZoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        -webkit-transform: scale(1);
                transform: scale(1);
        cursor: auto;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .FrisbeeImage {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: auto;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .FrisbeeImageZoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        -webkit-transform: scale(1);
                transform: scale(1);
        cursor: auto;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .OctoholicImage {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: auto;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .OctoholicImageZoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        -webkit-transform: scale(1);
                transform: scale(1);
        cursor: auto;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .POHImage1 {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: auto;
        cursor: initial;
        top:0;
        bottom:0;
    }
    .POHImage1Zoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        -webkit-transform: scale(1);
                transform: scale(1);
        cursor: auto;
        cursor: initial;
        top:0;
        bottom:0;
    }
    .POHImage2 {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: auto;
        cursor: initial;
        top:0;
        bottom:0;
    }
    .POHImage2Zoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        -webkit-transform: scale(1);
                transform: scale(1);
        cursor: auto;
        cursor: initial;
        margin-top: 2.5%;
        top:0;
        bottom:0;
    }
    .blurOverlay-blur {
        opacity: 0;
    }
    .headertext h3 {
        width: 85%;
    }
    .topgrid {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .bottomgrid {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .iframe {
        margin-top: 5%;
    }
    .brandguide {
        display: none;
    }
    .brandguideSmall {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: auto;
        cursor: initial;
        top:0;
        bottom:0;
    }
    .brandbrochure {
        display: none;
    }
    .brandbrochureSmall {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: auto;
        cursor: initial;
        top:0;
        bottom:0;
    }
}

.navigationArrows {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.arrowBack {
    padding-right: 25%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.arrowBack:hover {
    fill: #EBA362;
}

.arrowForward {
    padding-left: 25%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.arrowForward:hover {
    fill: #EBA362;
}
