.colorheader-g {
    display:flex;
    background-color: #6CAD7A;
    width:100%;
    height:500px;
}

.colorheader-p {
    display:flex;
    background-color: #A3B0E2;
    width:100%;
    height:500px;
}
.headertext{
    display:flex;
    flex-direction:column;
    align-items:center;
    color:white;
    width:100%;
    height:100%;
    justify-content: center;
}

.headertext h1{
    font-size: 65pt;
    margin: 0;
    text-align: center;
}

.headertext h3{
    font-size: 16pt;
    text-align: center;
    font-weight:300;
    width:50%;
}

.casestudy{
    width: 50%;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
    margin:auto;
    padding-top: 1%;
    margin-bottom: 30px;
}

.casestudy h2{
    margin-top: 30px;
    margin-bottom: 0px;
}

.casestudy p{
    line-height: 26px;
    font-weight: 300;
}

.brandguide {
    cursor: zoom-in;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/brandguide.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 33.48%;
    margin-right: 2.5%;
}

.brandguideZoom {
    cursor: zoom-out;
    z-index: 20;
    transform: translate(50%,25%) scale(1.75);
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/brandguide.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.brandguideSmall {
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/brandguide.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
    margin-top: 3%;
    display: none;
}

.brandguide-hidden {
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/brandguide.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 33.48%;
    margin-right: 2.5%;
    opacity: 0;
}

.brandbrochure {
    cursor: zoom-in;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/brandbrochure.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 33.48%;
    margin-left: 2.5%;
}

.brandbrochure-hidden {
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/brandbrochure.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 33.48%;
    margin-left: 2.5%;
    opacity:0;
}

.brandbrochureZoom {
    cursor: zoom-out;
    z-index: 20;
    transform: translate(-50%,25%) scale(1.75);
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/brandbrochure.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.brandbrochureSmall {
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/brandbrochure.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
    margin-top: 10%;
    display: none;
}

.UXimage {
    cursor: zoom-in;
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/bankapp.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 106.87%;
}

.UXimageZoom {
    cursor: zoom-out;
    z-index: 15;
    transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/bankapp.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 106.87%;
}

.AccrueImage {
    cursor: zoom-in;
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/accrue.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.AccrueImageZoom {
    cursor: zoom-out;
    z-index: 15;
    transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/accrue.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.FrisbeeImage {
    cursor: zoom-in;
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/frisbeesite.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.FrisbeeImageZoom {
    cursor: zoom-out;
    z-index: 15;
    transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/frisbeesite.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 66.67%;
}

.OctoholicImage {
    cursor: zoom-in;
    z-index: 15;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/octoholicproject.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 44.83%;
}

.OctoholicImageZoom {
    cursor: zoom-out;
    z-index: 15;
    transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/octoholicproject.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 44.83%;
}

.POHImage1 {
    cursor: zoom-in;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/poh2.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 62.78%;
}

.POHImage1Zoom {
    cursor: zoom-out;
    z-index: 15;
    transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/poh2.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 62.78%;
}

.POHImage2 {
    cursor: zoom-in;
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/poh1.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 62.78%;
    margin-top: 2.5%;
}

.POHImage2Zoom {
    cursor: zoom-out;
    z-index: 15;
    transform: scale(1.5);
    height: auto;
    width:100%;
    background-image: url(./ProjectImages/poh1.png);
    background-repeat: no-repeat;
    background-size: contain; 
    padding-top: 62.78%;
}

.container {
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
}

.blurOverlay {
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    background-color: #7777;
    z-index: -1;
    position: absolute;
}

.blurOverlay-blur {
    top: 0;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(4px);
    opacity: 1;
    z-index: 10;
    position: absolute;
}

.blurOverlay-brand {
    top: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
    background-color: #7777;
    z-index: -1;
    position: absolute;
}

.blurOverlay-brand-blur {
    top: 0;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(4px);
    opacity: 1;
    z-index: 10;
    position: absolute;
}

.boldLink {
    font-weight: 600;
}

.iframe{
    border: none;
    width: 100%;
    height: 400px;
}

.imagegrid{
    display:flex;
    flex-direction: column;
    width:75%;
}

.topgrid{
    display:flex;
    flex-direction: row;
    align-items: center;

}

.bottomgrid{
    display:flex;
    flex-direction: row;
}

@media (max-width:1200px) {
    .casestudy {
        width: 60%;
    }
    .navigationArrows {
        width: 60% !important;
    }
    .UXimage {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .AccrueImage {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .FrisbeeImage {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .OctoholicImage {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .POHImage1 {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .POHImage2 {
        max-height: 100%;
        height: auto;
        object-fit: contain;
    }
    .brandguide {
        max-height: 100%;
        height: auto;
        object-fit: contain;
        width: 50%;
        display: none;
    }
    .brandbrochure {
        max-height: 100%;
        height: auto;
        object-fit: contain;
        width: 50%;
        display: none;
    }
    .brandguideSmall {
        display: block;
    }
    .brandbrochureSmall {
        display: block;
    }
    .blurOverlay-brand-blur {
        display: none;
    }
    .brandguideZoom {
        display: none;
    }
    .brandbrochureZoom {
        display: none;
    }
    .topgrid {
        flex-direction: column;
        align-items: center;
    }
    .bottomgrid {
        flex-direction: column;
    }
    .iframe {
        margin-top: 5%;
    }
}

@media (max-width: 750px) {
    .headertext h1 {
        font-size: 35pt;
    }
    .casestudy {
        width: 75%;
    }
    .navigationArrows {
        width: 75% !important;
    }
    .UXimage {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .UXimageZoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        transform: scale(1);
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .AccrueImage {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .AccrueImageZoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        transform: scale(1);
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .FrisbeeImage {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .FrisbeeImageZoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        transform: scale(1);
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .OctoholicImage {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .OctoholicImageZoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        transform: scale(1);
        cursor: initial;
        margin:0;
        top:0;
        bottom:0;
    }
    .POHImage1 {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: initial;
        top:0;
        bottom:0;
    }
    .POHImage1Zoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        transform: scale(1);
        cursor: initial;
        top:0;
        bottom:0;
    }
    .POHImage2 {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: initial;
        top:0;
        bottom:0;
    }
    .POHImage2Zoom {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        transform: scale(1);
        cursor: initial;
        margin-top: 2.5%;
        top:0;
        bottom:0;
    }
    .blurOverlay-blur {
        opacity: 0;
    }
    .headertext h3 {
        width: 85%;
    }
    .topgrid {
        flex-direction: column;
    }
    .bottomgrid {
        flex-direction: column;
    }
    .iframe {
        margin-top: 5%;
    }
    .brandguide {
        display: none;
    }
    .brandguideSmall {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: initial;
        top:0;
        bottom:0;
    }
    .brandbrochure {
        display: none;
    }
    .brandbrochureSmall {
        min-height: 100%;
        height: auto;
        object-fit: contain;
        cursor: initial;
        top:0;
        bottom:0;
    }
}

.navigationArrows {
    width: 50%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.arrowBack {
    padding-right: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.arrowBack:hover {
    fill: #EBA362;
}

.arrowForward {
    padding-left: 25%;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
}

.arrowForward:hover {
    fill: #EBA362;
}