@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-family:'Source Sans Pro';
}

.Content{
  min-height: 70vh;
}